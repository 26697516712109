import React, { useState, useRef } from "react";
//import SlideButton from "../common/SlideButton";
import ContactOption from "./ContactOption";
//import nodemailer from "nodemailer";
//import dotenv from "dotenv";
//dotenv.config();

import "./Contact.css";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const Contact = () => {
  //const [captachaDone, setCaptachaDone] = useState(false);
  const [sendButtonActive, setSendButtonActive] = useState(false);
  const form = useRef();
  const { t } = useTranslation();
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [senderMessage, setSenderMessage] = useState("");

  const [sendingLabel, setSendingLabel] = useState(false);

  const onCaptchaChange = (value) => {
    //console.log("Site key-done :", process.env.site_key);
    setSendButtonActive(true);

    //setCaptachaDone(true);
  };
  const sendEmail = (e) => {
    setSendingLabel(true);

    e.preventDefault();
    
if (sendEmail==="" || senderName ==="" || senderMessage ==="" ) return
    //emailjs.sendForm(serviceID, templateID, templateParams, publicKey);
    emailjs
      .sendForm(
        process.env.emailjs_serviceID,
        process.env.emailjs_templateID,
        form.current,
        process.env.emailjs_publicKey
      )
      .then(
        (result) => {
          alert("Thank you for your contact, we received your email 💗");
          console.log("send rs : ", result.text);
          setSendingLabel(false);
          setSendButtonActive(false);
          //reset my form
          setSenderName("");
          setSenderEmail("");
          setSenderMessage("");
          setSenderPhone("");
          setSubject("");
        },
        (error) => {
          console.log("===> Problem ", error.text);
          setSendingLabel(false);
        }
      );
  };

  return (
    <section id="contact">
      <div className="blur-layer">
        <h1>{t("contact.title1")}</h1>
        <div className="contact-wrapper">
          <div className="contact-details">
            <ContactOption
              title={"Email"}
              text1={"contact@ccn-services.com"}
              link1={"mailto:contact@ccn-services.com"}
            />
            <ContactOption
              title={t("contact.phone_title")}
              text1={"Phone 1: +216 22 583 976"}
              link1={"#"}
              text2={"Phone 2: +216 52 376 596"}
              link2={"#"}
            />
            <ContactOption
              title={"WhatsApp"}
              text1={"+216 22 583 976"}
              link1={"https://api.whatsapp.com/send?phone=21622583976"}
              text2={"+216 52 376 596"}
              link2={"https://api.whatsapp.com/send?phone=21652376596"}
            />
          </div>
          <div className="contact-container">
            <form
              action=""
              className="contact-form"
              onSubmit={sendEmail}
              ref={form}
            >
              <div className="row1">
                <input
                  type="text"
                  name="name"
                  value={senderName}
                  onChange={(e) => setSenderName(e.target.value)}
                  placeholder={t("contact.your_name")}
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={senderEmail}
                  onChange={(e) => setSenderEmail(e.target.value)}
                  placeholder={t("contact.your_email")}
                  required
                />
                <input
                  type="text"
                  name="phone"
                  value={senderPhone}
                  onChange={(e) => setSenderPhone(e.target.value)}
                  placeholder={t("contact.your_phone")}
                  required
                />
                <input
                  type="text"
                  name="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder={t("contact.message_subject")}
                  required
                />
              </div>
              <div className="row2">
                <textarea
                  name="message"
                  rows="14"
                  value={senderMessage}
                  onChange={(e) => setSenderMessage(e.target.value)}
                  placeholder={t("contact.your_message")}
                  required
                ></textarea>
              </div>

              <div className="check-bots">
                <ReCAPTCHA
                  sitekey={process.env.recaptcha_siteKey}
                  onChange={onCaptchaChange}
                  onExpired={() => {
                    setSendButtonActive(false);
                    //setCaptachaDone(false);
                  }}
                />
              </div>

              <div className="send-button">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={sendEmail}
                  disabled={!sendButtonActive || sendingLabel}
                >
                  {sendingLabel ? "Sending..." : t("contact.send_message")}
                </button>
              </div>
              
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
