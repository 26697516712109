import React from "react";

import "./Certificates.css";
import Img1 from "../../assets/experts.jpg";
import { RiCustomerService2Fill } from "react-icons/ri";
import { BiSolidCheckShield } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const Certificates = () => {
  const { t } = useTranslation();
  return (
    <section id="certificates">
      <div className="experts_container">
        <div className="details">
          <div className="title" data-aos="zoom-in" data-aos-duration="1500">
            <RiCustomerService2Fill size={40} />
            <h1>{t("expert.title1")}</h1>
          </div>
          <p data-aos="fade-right" data-aos-duration="1500">
            {t("expert.desc")}
          </p>
         

          <ul data-aos="fade-up" data-aos-duration="1500">
            <li>
              <BiSolidCheckShield className="icon" /> Cisco CCNA.
              
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Comptia a+ network+.
              
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Microsoft MCSA
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Microsoft security ISA
              server
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> VMWARE VCTA VCP
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Veritas : Backup Exec,
              System Recovery,
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> NetBackup
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Symantec EndPoint
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Lean Green Belt
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Lean Black Belt
            </li>
            <li>
              <BiSolidCheckShield className="icon" /> Lean Six sigma
            </li>
          </ul>
        </div>
        {/*<div className="picture">
          <img src={Img1} alt="Our services " className="image" />
  </div>*/}
      </div>
    </section>
  );
};

export default Certificates;
